import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtmlPipe } from '../safe-html.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatDialogActions, MatDialogContent, SafeHtmlPipe, MatButtonModule, MatDialogClose, TranslateModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {

  @Output() confirmAction = new EventEmitter<boolean>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly translate: TranslateService) {

  }

  confirm(): void {

    this.confirmAction.emit(true);

  }
  cancel(): void {

    this.confirmAction.emit(false);
  }
}
